import { api } from 'api/api';
import { useOrganization } from 'api/organizations/useOrganization';
import { useSubscriptionTiers } from 'api/subscriptionTiers/useSubscriptionTiers';
import { useSubscription } from 'api/subscriptions/useSubscription';
import { Arrow } from 'assets/svgcomponents/Arrow';
import { CircleCheck } from 'assets/svgcomponents/CircleCheck';
import { alert } from 'components/alerts/Alert';
import { LargeButton } from 'components/buttons/LargeButton';
import { TextButton } from 'components/buttons/TextButton';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { toast } from 'components/toast/Toast';
import { useTheme } from 'context/theme';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function SelectTier() {
  const { data: organization, isLoading: isLoadingOrganization } = useOrganization();
  const { data: subscriptionTiers, isLoading: isLoadingSubscriptionTiers } = useSubscriptionTiers();
  const { data: subscription, isLoading: isLoadingSubscription } = useSubscription();

  const [isCanceling, setIsCanceling] = useState(false);
  const [isCustomTier, setIsCustomTier] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();

  console.log(subscriptionTiers);

  if (isLoadingOrganization || isLoadingSubscription || isLoadingSubscriptionTiers) {
    return <FullscreenLoading />;
  }

  const handleCancelSubscription = async () => {
    setIsCanceling(true);
    await api.subscriptions
      .cancelSubscription(organization!.id)
      .then(() => {
        toast.success({ description: 'Abonnementet ble avsluttet' });
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto en feil' });
      })
      .finally(() => {
        setIsCanceling(false);
        navigate(`/${organization!.handle}/innstillinger/abonnement`);
      });
  };

  const handleResumeSubscription = async () => {
    setIsCanceling(true);
    await api.subscriptions
      .resumeSubscription(organization!.id)
      .then(() => {
        toast.success({ description: 'Kansellering ble avbrutt' });
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto en feil' });
      })
      .finally(() => {
        setIsCanceling(false);
        navigate(`/${organization!.handle}/innstillinger/abonnement`);
      });
  };

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex flex-col justify-between gap-y-5 pt-5">
        <TextButton
          backgroundColor="light"
          className="flex"
          onClick={() => navigate(-1)}
          icon={<Arrow className="h-[21px]" />}
          iconPlacement="left">
          Tilbake
        </TextButton>
        <h1 className="bigheading" style={{ color: theme.label.primary }}>
          Endre Abonnement
        </h1>
      </div>
      {!subscription?.cancelAtPeriodEnd && (
        <LargeButton
          defaultStyle="critical"
          backgroundColor="light"
          onClick={() =>
            alert.error({
              title: 'Ønsker du å avslutte abonnementet?',
              description: `Abonnementet vil være aktivt frem til ${
                subscription?.billingPeriodEnd
                  ? new Date(subscription?.billingPeriodEnd)?.toLocaleDateString('no-NO', {
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric',
                    })
                  : 'neste faktureringsdato.'
              }`,
              actionLabel: 'Avslutt',
              onAction: handleCancelSubscription,
              loading: isCanceling,
            })
          }
          loading={isCanceling}>
          Avslutt abonnement
        </LargeButton>
      )}
      {subscription?.cancelAtPeriodEnd && (
        <LargeButton
          defaultStyle="critical"
          backgroundColor="light"
          onClick={handleResumeSubscription}
          loading={isCanceling}>
          Avbryt kansellering
        </LargeButton>
      )}
      <div className="flex h-[550px] max-h-[550px] max-w-full flex-1 items-start justify-start space-x-8 overflow-x-auto">
        {subscriptionTiers &&
          subscriptionTiers
            .filter((tier) => tier.isPublic)
            .sort((a, b) => a.usersLimit - b.usersLimit)
            .map((tier, index) => {
              const price = tier.prices?.find((price) => price.isDefault)?.amount ?? 0;
              let priceString = 'Gratis';
              let buttonString = 'Bytt til gratis';
              let buttonDisabled = false;
              let buttonOnClick: null | (() => void) = null;

              if (price > 0) {
                priceString = `${price}KR/MND`;
                buttonString = `Bytt til ${tier.name}`;
              }

              if (tier.name === 'Enterprise') {
                priceString = 'Ta kontakt';
                buttonString = 'Ta kontakt';
                buttonOnClick = () => (window.location.href = 'mailto:hei@logiskbrist.no');
              }

              if (organization?.subscription?.subscriptionTier?.stripeProductId === tier.stripeProductId) {
                buttonString = 'Aktiv';
                buttonDisabled = true;
              }

              if (tier.name === 'Enkel') {
                if (!organization?.subscription?.stripeSubscriptionId) {
                  buttonString = 'Aktiv';
                  buttonDisabled = true;
                }
                buttonOnClick = () =>
                  alert.error({
                    title: 'Ønsker du å bytte til gratis abonnement?',
                    description: `Du vil beholde det nåværende abonnementet frem til ${
                      subscription?.billingPeriodEnd
                        ? new Date(subscription?.billingPeriodEnd)?.toLocaleDateString('no-NO', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                          })
                        : 'neste faktureringsdato.'
                    }`,
                    actionLabel: 'Bytt til gratis',
                    onAction: handleCancelSubscription,
                  });
              }

              //TODO: Separate sider for å velge abonnement, og for å endre på abonnement. Når man velger abonnement skal man betale med checkout session, når man endrer skal subscription oppdateres.

              return (
                <div
                  key={tier.id}
                  className="flex h-full w-[340px] min-w-[340px] flex-col items-center space-y-10 rounded-large p-10"
                  style={{
                    backgroundColor: index % 2 === 0 ? theme.background.inverse : theme.base.accent,
                    color: theme.label.onaccent,
                  }}>
                  <p className="leadlarge">{tier.name}</p>
                  <p className="bigheading">{priceString}</p>
                  <ul className="space-y-2">
                    {tier.features &&
                      tier.features.map((feature) => {
                        return (
                          <li className="smalltext flex items-center space-x-1 leading-tight" key={feature.title}>
                            <div>
                              <CircleCheck fill={theme.label.onaccent} className="h-[24px] w-[24px]" />
                            </div>
                            <p>{feature.title}</p>
                          </li>
                        );
                      })}
                  </ul>
                  <div className="flex flex-1 items-end">
                    <LargeButton
                      defaultStyle={index % 2 === 0 ? 'indigo' : 'white'}
                      backgroundColor={index % 2 === 0 ? 'dark' : 'light'}
                      className="justify-self-end"
                      onClick={buttonOnClick as any}
                      disabled={buttonDisabled || isCustomTier}>
                      {buttonString}
                    </LargeButton>
                  </div>
                </div>
              );
            })}
      </div>
    </div>
  );
}
