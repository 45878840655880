import BackButton from 'components/buttons/BackButton';
import { LargeButton } from 'components/buttons/LargeButton';
import Input from 'components/input-field/Input';
import { toast } from 'components/toast/Toast';
import { useStoredUser } from 'context/UserContext';
import { useTheme } from 'context/theme';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { userService } from 'services/userService';
import { formatAccountNumber, formatPhoneNumber, handlePositionChange } from 'utils/format';
import { validAccountNumber } from 'utils/validAccountNumber';
import * as Yup from 'yup';

type FormValues = {
  givenName: string;
  familyName: string;
  phoneNumber: string;
  email: string;
  streetAddress: string;
  postalCode: string;
  region: string;
  accountNumber: string;
};

export default function UserSettings() {
  const { user, setUser } = useStoredUser();
  const navigate = useNavigate();
  const theme = useTheme();
  const [changedValues, setChangedValues] = useState({
    email: true,
    streetAddress: true,
    postalCode: true,
    region: true,
    accountNumber: true,
  });

  function handlePostalCodeChange(
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
  ) {
    setChangedValues((prev) => ({ ...prev, postalCode: true }));
    const value = e.currentTarget.value.toString().replace(/ /g, '');
    if (value.length > 4) return;
    if (value.length === 0) setFieldValue('postalCode', '');
    if (!/^[0-9 ]+$/.test(value)) return;
    setFieldValue('postalCode', value);
  }

  const handleSubmit = async (values: FormValues) => {
    try {
      await userService.update(user.id, {
        email: values.email,
        streetAddress: values.streetAddress,
        postalCode: values.postalCode,
        region: values.region,
        accountNumber: values.accountNumber.match(/\d+/g)!.join(''),
      });
      toast.success({ description: 'Endringer lagret' });
      setUser();
    } catch (err) {
      toast.error({ description: (err as string) || 'Noe gikk galt. Kontakt support om problemet vedvarer.' });
    }
  };

  // TODO: Implementer automatisk henting av poststed og evt postnummer
  return (
    <div className="flex flex-col gap-y-10">
      <div className="flex flex-col justify-between gap-y-5 pt-5" style={{ color: theme.label.primary }}>
        <BackButton />
        <h1 className="bigheading">Personlige opplysninger</h1>
      </div>
      <Formik
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          await handleSubmit(values);
          setSubmitting(false);
        }}
        initialValues={
          {
            givenName: user.givenName ?? '',
            familyName: user.familyName ?? '',
            phoneNumber: formatPhoneNumber(user.phoneNumber ?? ''),
            email: user.email ?? '',
            streetAddress: user.streetAddress ?? '',
            postalCode: user.postalCode ?? '',
            region: user.region ?? '',
            accountNumber: user.accountNumber ?? '',
          } as FormValues
        }
        validationSchema={Yup.object({
          email: Yup.string().email('Ugyldig e-post').required('Påkrevd'),
          streetAddress: Yup.string().required('Påkrevd'),
          postalCode: Yup.number()
            .positive('Ugyldig postnummer')
            .integer('Ugyldig postnummer')
            .test('len', 'Ugyldig postnummer', (val) => val?.toString().length === 4)
            .required('Påkrevd')
            .typeError('Ugyldig postnummer'),
          region: Yup.string().required('Påkrevd'),
          accountNumber: Yup.string().test('format', 'Ugyldig kontonummer', (val) =>
            validAccountNumber((val ?? '').toString()),
          ),
        })}
        enableReinitialize>
        {({ errors, values, handleChange, handleSubmit, setFieldValue }) => (
          <Form className="flex flex-col gap-y-10" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-y-4">
              <Input.Text width="md" disabled label="Fornavn" value={values.givenName} />
              <Input.Text width="md" disabled label="Etternavn" value={values.familyName} />
              <Input.Text width="md" disabled label="Telefon" className="w-full" value={values.phoneNumber} />
              <Input.Text
                width="lg"
                label="E-post"
                value={values.email}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, email: true }));
                  handleChange('email')(e);
                }}
                error={!changedValues.email ? errors.email : undefined}
              />
              <Input.Text
                label="Adresse"
                width="lg"
                value={values.streetAddress}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, streetAddress: true }));
                  handleChange('streetAddress')(e);
                }}
                error={!changedValues.streetAddress ? errors.streetAddress : undefined}
              />
              <div className="flex gap-x-4">
                <Input.Text
                  width="sm"
                  label="Postnummer"
                  value={values.postalCode}
                  onChange={(e) => handlePostalCodeChange(e, setFieldValue)}
                  error={!changedValues.postalCode ? errors.postalCode : undefined}
                />
                <Input.Text
                  width="md"
                  label="Poststed"
                  value={values.region.toUpperCase()}
                  onChange={(e) => {
                    setChangedValues((prev) => ({ ...prev, region: true }));
                    handleChange('region')(e);
                  }}
                  error={!changedValues.region ? errors.region : undefined}
                />
              </div>
              <Input.Text
                label="Kontonummer"
                width="md"
                value={values.accountNumber}
                onChange={(e) => {
                  setChangedValues((prev) => ({ ...prev, accountNumber: true }));
                  if (e.currentTarget.value.length === 0) setFieldValue('accountNumber', '');
                  if (!/^(?!(?:\D*\d){12,})[\d .]+$/.test(e.currentTarget.value)) return;

                  handlePositionChange(e, formatAccountNumber(e.currentTarget.value.toString()));
                  setFieldValue('accountNumber', formatAccountNumber(e.currentTarget.value.toString()));
                }}
                error={!changedValues.accountNumber ? errors.accountNumber : undefined}
              />
            </div>
            <div className="flex flex-row gap-x-4">
              <LargeButton
                backgroundColor="light"
                defaultStyle="indigo"
                type="submit"
                onClick={() =>
                  setChangedValues({
                    accountNumber: false,
                    email: false,
                    postalCode: false,
                    region: false,
                    streetAddress: false,
                  })
                }>
                Lagre
              </LargeButton>
              {/* <LargeButton
                backgroundColor="light"
                defaultStyle="critical"
                type="button"
                onClick={() => {
                  // eslint-disable-next-line no-restricted-globals
                  if (confirm('Er du sikker på at du vil slette kontoen din?')) {
                    userService._delete(user.id);
                    toast.success({ description: 'Konto slettet' });
                    authService.logout();
                  }
                }}
              >
                Slett bruker
              </LargeButton> */}
              <LargeButton
                backgroundColor="light"
                defaultStyle="white"
                type="button"
                onClick={() => navigate('passord')}>
                Endre passord
              </LargeButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
