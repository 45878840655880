import { CellContext } from '@tanstack/react-table';
import { Account, Project } from '__generated__/UtleggsappenApi';
import { useDepartment } from 'api/departments/useDepartment';
import { Plus } from 'assets/svgcomponents/Plus';
import { Trash } from 'assets/svgcomponents/Trash';
import { FullscreenLoading } from 'components/layout/LoadingIndicator';
import { alert } from 'components/alerts/Alert';
import { SmallButton } from 'components/buttons/SmallButton';
import Input from 'components/input-field/Input';
import SearchBar from 'components/input-field/SearchBar';
import Table from 'components/table/Table';
import { DefaultCell, HeaderCell, TagWrapper } from 'components/table/TableComponents';
import { toast } from 'components/toast/Toast';
import { Form, Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { departmentService } from 'services/departmentService';
import * as Yup from 'yup';

export default function Projects() {
  const { departmentId } = useParams();
  const { data: department, isLoading: isLoadingDepartment, mutate: mutateDepartment } = useDepartment(departmentId!);
  const [searchFilter, setSearchFilter] = useState('');
  const [isLoadingAdding, setIsLoadingAdding] = useState(false);
  const [isLoadingRemoving, setIsLoadingRemoving] = useState(false);
  const submitFormRef = useRef<() => Promise<void>>();

  const columns = useMemo(
    () => [
      {
        accessorKey: 'title',
        cell: (value: CellContext<Project, string>) => (
          <DefaultCell className="smalltextbold">{value.getValue()}</DefaultCell>
        ),
        header: () => <HeaderCell>Navn</HeaderCell>,
        size: 350,
      },
      {
        accessorKey: 'id',
        header: () => null,
        cell: (id: CellContext<Account, string>) => (
          <TagWrapper className="flex w-full justify-end">
            <SmallButton
              backgroundColor="light"
              defaultStyle="critical"
              icon={<Trash className="h-4 w-4" fill="currentColor" />}
              iconPlacement="left"
              onClick={() =>
                alert.error({
                  title: 'Fjern prosjekt',
                  description: 'Er du sikker på at du vil fjerne prosjektet?',
                  actionLabel: 'Fjern',
                  onAction: () => handleRemove(id.getValue()),
                })
              }
              loading={isLoadingRemoving}>
              Fjern
            </SmallButton>
          </TagWrapper>
        ),
      },
    ],
    [],
  );

  useEffect(() => {
    if (department?.projects) {
      department.projects.sort((a, b) => {
        return a.title.toLocaleLowerCase() > b.title.toLocaleLowerCase() ? 1 : -1;
      });
    }
  }, [department?.projects]);

  const handleAdd = async (project: Partial<Project>) => {
    setIsLoadingAdding(true);
    await departmentService
      .addProjects(department!.id, [project])
      .then(() => {
        toast.success({ description: 'Prosjektet ble lagt til' });
        mutateDepartment();
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto en feil' });
      })
      .finally(() => {
        alert.close();
        setIsLoadingAdding(false);
      });
  };
  const handleRemove = async (projectId: string) => {
    setIsLoadingRemoving(true);
    await departmentService
      .removeProjects(department!.id, [projectId])
      .then(() => {
        toast.success({ description: 'Prosjektet ble fjernet' });
        mutateDepartment();
      })
      .catch(() => {
        toast.error({ description: 'Det oppsto en feil' });
      })
      .finally(() => {
        setIsLoadingRemoving(false);
      });
  };

  return (
    <>
      <div className="flex flex-1 flex-row justify-end gap-x-4">
        <SearchBar setSearchFilter={setSearchFilter} searchFilter={searchFilter} />
        <SmallButton
          backgroundColor="light"
          defaultStyle="indigo"
          className="h-fit px-3.5 py-2"
          icon={<Plus className="h-4 w-4" />}
          iconPlacement="left"
          onClick={() => {
            alert.info({
              title: 'Legg til prosjekt',
              actionLabel: 'Legg til',
              onAction: () => {
                if (submitFormRef.current) submitFormRef.current();
              },
              loading: isLoadingAdding,
              disableDefaultAction: true,
              children: (
                <Formik
                  onSubmit={(formValues) => handleAdd(formValues)}
                  validateOnChange={false}
                  validateOnBlur={false}
                  initialValues={{ title: '' }}
                  validationSchema={Yup.object({ title: Yup.string().required('Påkrevd') })}>
                  {({ values, handleSubmit, handleBlur, errors, handleChange, submitForm }) => {
                    submitFormRef.current = submitForm;
                    return (
                      <Form onSubmit={handleSubmit} className="flex w-full flex-col items-center">
                        <Input.Text
                          focusOnMount
                          label="Navn på prosjekt"
                          value={values.title || ''}
                          onChange={handleChange}
                          error={errors.title as any}
                          onBlur={handleBlur}
                          name="title"
                        />
                      </Form>
                    );
                  }}
                </Formik>
              ),
            });
          }}>
          Legg til prosjekt
        </SmallButton>
      </div>
      <div className="flex h-full w-full flex-col">
        {!isLoadingDepartment && department ? (
          <Table
            disableHover={false}
            data={department.projects!}
            filter={searchFilter}
            columns={columns}
            gridCols="minmax(0, 5fr) minmax(0, 7fr) minmax(150px, 4fr) minmax(96px, 2fr) 68px"
            sortBy={[{ id: 'title', desc: false }]}
          />
        ) : (
          <FullscreenLoading />
        )}
      </div>
    </>
  );
}
